import React, { useEffect, useState } from "react";
import AdminSidebar from "./AdminSidebar";
import AdminFooter from "./AdminFooter";
import AdminHeader from "./AdminHeader";
import CKEditor from "./MyEditor";
import {
  server_post_data,
  Get_All_Blogs_PK,
  Update_blog_data,
} from "../../ServiceConnection/serviceconnection.js";
import {
  handleError,
  handleSuccessSession,
} from "../../CommonJquery/CommonJquery.js";
import { useParams, Link } from "react-router-dom";

function BlogDashboardAdd() {
  const { id } = useParams();
  console.log(id);
  const [editorData, setEditorData] = useState("");
  const [error_show, setErrorShow] = useState("");
  const [editorDataMainID, setEditorDataMainID] = useState("0");
  const [editBlogData, setEditBlogData] = useState({});
  const [showLoaderAdmin, setShowLoaderAdmin] = useState(false);

  // test
  const [title, setTitle] = useState({});
  const [tag, setTag] = useState({});
  const [image, setImage] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const fd = new FormData();
      fd.append("primary_id", id);
      try {
        setShowLoaderAdmin(true);
        const response = await server_post_data(Get_All_Blogs_PK, fd);
        if (response.data.error) {
          handleError(response.data.message);
        } else {
          console.log(response.data.message[0]);
          const blogData = response.data.message[0];
          setEditBlogData(blogData);
          console.log(blogData.tag_line);
          setEditorData(blogData.description);
          setEditorDataMainID(blogData.primary_id);
          setTag(blogData.tag_line);
          setTitle(blogData.title_name);
          setImage(blogData.image_url);
        }
      } catch (error) {
        handleError(error.message);
      } finally {
        setShowLoaderAdmin(false);
      }
    };
    console.log(editBlogData);

    fetchData();
  }, [id]);

  const handleEditorChange = (event, editor) => {
    setEditorData(editor.getData());
  };

  const handleSave = async () => {
    console.log(title, tag, image);
    try {
      setShowLoaderAdmin(true);

      const formData = new FormData();
      formData.append("image", image);
      formData.append("title_name", title);
      formData.append("tag_Line", tag);
      formData.append("description", editorData);
      formData.append(" primary_id ", id);

      const response = await server_post_data(Update_blog_data, formData);
      if (response.data.error) {
        handleError(response.data.Message);
      } else {
        console.log(response);
        handleSuccessSession(response.data.Message, "/blogDashboardView");
      }
    } catch (error) {
      setShowLoaderAdmin(false);
    }
  };

  return (
    <div className="app-container app-theme-white body-tabs-shadow fixed-header">
      <div className={showLoaderAdmin ? "loading_website" : ""}></div>
      <AdminHeader />

      <div className="app-main">
        <AdminSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <div className="app-page-title">
              <div className="page-title-wrapper">
                <div className="page-title-heading">
                  <Link to="/admindashboard">
                    <div className="page-title-icon">
                      <i className="fa fa-home icon-gradient bg-mean-fruit"></i>
                    </div>
                  </Link>
                  <div>
                    Website Management &gt; Blogs
                    <div className="page-title-subheading">
                      Add and Edit Blog
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="main-card mb-3 card">
              <div className="card-body">
                <h5 className="card-title">Add and Edit Blog</h5>
                <form className="needs-validation" id="CarrerformData">
                  <div className="form-row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="validationCustom01">
                        Images Url (Only Paste)
                        <span className="red_show">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control  trio_mandatory"
                        name="image_url
                        "
                        id="image"
                        maxLength={300}
                        onChange={(e) => setImage(e.target.value)}
                        placeholder="Enter Images url"
                        defaultValue={editBlogData.image_url || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="validationCustom01">
                        Title<span className="red_show">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control  trio_mandatory"
                        name="title_name"
                        id="title_name"
                        maxLength={100}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder="Enter Title"
                        defaultValue={editBlogData.title_name || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="validationCustom01">
                        Introduction<span className="red_show">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control  trio_mandatory"
                        name="tag_line"
                        id="tag_line"
                        maxLength={300}
                        onChange={(e) => setTag(e.target.value)}
                        placeholder="Enter Introduction"
                        defaultValue={editBlogData.tag_line || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="validationCustom01">
                        Author<span className="red_show">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control  trio_mandatory"
                        name="author_data"
                        id="author_data"
                        placeholder="Enter Author"
                        maxLength={100}
                        defaultValue={editBlogData.author || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom01">
                        Description<span className="red_show">*</span>
                      </label>
                      <CKEditor
                        data_showe={editorData}
                        onChange={handleEditorChange}
                      />
                      <span className="condition_error">{error_show}</span>
                    </div>
                  </div>

                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() =>
                      handleSave("CarrerformData", Update_blog_data)
                    }
                  >
                    Save
                  </button>
                </form>
              </div>
            </div>
          </div>
          <AdminFooter />
        </div>
      </div>
    </div>
  );
}
export default BlogDashboardAdd;
